const userAgent = navigator.userAgent;

const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

const IsWeChat = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('micromessenger');
};

const isWeChat = IsWeChat();

const mobileReg = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
const isMobile = !!navigator.userAgent.match(mobileReg);
// export const screenWidth = window.innerWidth;
// export const screenHeight = window.innerHeight;
// const isMobile = screenHeight / screenWidth > 1.6;
// const isMobile = !!navigator.userAgent.match(/AppleWebKit.*Mobile.*/);

export {
    isIOS,
    isWeChat,
    isMobile,
};
