import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { get } from './request';
import routes from './router';
import 'antd-mobile/dist/antd-mobile.css';

function App() {
    return (
        <SWRConfig value={{ fetcher: get, focusThrottleInterval: 1e4, shouldRetryOnError: false }}>
            <BrowserRouter>
                <div className="App-content">
                    <Switch>
                        {routes.map((item) => (
                            <Route key={item.path} path={item.path} component={item.component} exact={item.exact} />
                        ))}
                    </Switch>
                </div>
            </BrowserRouter>
        </SWRConfig>
    );
}

export default App;
