import asyncComponent from './asyncImport.js';

const routes = [
    {
        path: '/',
        exact: true,
        component: asyncComponent(() => import(`../pages/courseDetails`)),
    },
    {
        path: '/unilink',
        component: asyncComponent(() => import(`../pages/unilink`)),
    },
    {
        path: '/lesson',
        component: asyncComponent(() => import(`../pages/courseDetails`)),
    },
    {
        path: '/theme',
        component: asyncComponent(() => import(`../pages/specialDetails`)),
    },
    {
        path: '/about',
        component: asyncComponent(() => import(`../pages/protocol`)),
    },
    {
        path: '/newWelfare',
        component: asyncComponent(() => import(`../pages/newWelfare`)),
    },
    {
        path: '/giftCardVip',
        component: asyncComponent(() => import(`../pages/giftCardVip`)),
    },
    {
        path: '/giftCardCourse',
        component: asyncComponent(() => import(`../pages/giftCardCourse`)),
    },
    {
        path: '/giftExpCard',
        component: asyncComponent(() => import(`../pages/giftExpCard`)),
    },
    {
        path: '/activityDetail',
        component: asyncComponent(() => import(`../pages/activityDetail`)),
    },
    {
        path: '/collectDetail',
        component: asyncComponent(() => import(`../pages/collectDetail`)),
    },
    {
        path: '/payResult',
        component: asyncComponent(() => import(`../pages/payResult`)),
    },
    {
        path: '/membExch',
        component: asyncComponent(() => import(`../pages/membExch`)),
    },
    {
        path: '/productDetail',
        component: asyncComponent(() => import(`../pages/productDetail`)),
    },
];

export default routes;
